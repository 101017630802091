import React, {Component} from 'react';
import '../css/Home.css';

import kollarcoolprofile from '../images/peter_kollar_cool.jpg';
import petrascoolprofile from '../images/peter_petras_cool.png';
import kollarnormalprofile from '../images/peter_kollar_normal2.jpg';
import petrasnormalprofile from '../images/peter_petras_normal2.jpg';
import ProfileImage from './ProfileImage';
class About extends Component  {
    render(){
        return(
        <div className="container">
        <h3 className="center">About us</h3>
            <div className="row aboutrow">
                <div className="col-12 center">
                    DoubleMaker's team is ready to reform the technology background of Tennis worldwide with
                    more than 20 years of tennis experience and 10 years of coding.
                    If you are interested in applications connected to tennis, do not hesitate to contact us!

                </div>

                <ProfileImage className="business-image about-image pointer" classNameHidden="business-image about-image pointer display-none" email="&#100;&#111;&#117;&#098;&#108;&#101;&#109;&#097;&#107;&#101;&#114;&#064;&#112;&#101;&#116;&#107;&#111;&#108;&#115;&#121;&#115;&#116;&#101;&#109;&#115;&#046;&#099;&#111;&#109;" profilename="Péter Kollár" position={"Chief Executive"} businessimage={kollarnormalprofile} coolimage={kollarcoolprofile} />
                <ProfileImage className="business-image about-image pointer" classNameHidden="business-image about-image pointer display-none" email="&#100;&#111;&#117;&#098;&#108;&#101;&#109;&#097;&#107;&#101;&#114;&#064;&#112;&#101;&#116;&#107;&#111;&#108;&#115;&#121;&#115;&#116;&#101;&#109;&#115;&#046;&#099;&#111;&#109;" profilename="Péter Petrás" position="Chief Executive" businessimage={petrasnormalprofile} coolimage={petrascoolprofile} />
              
            </div>
        </div>
    )
        }
}

export default About;