import React, {Component} from 'react';
import {NavLink,withRouter} from 'react-router-dom';
import '../css/Navbar.css';
 import {
     Collapse,
     Navbar,
     NavbarToggler,
     NavbarBrand,
     Nav,
     NavItem,
    //  NavLink,
    } from 'reactstrap';


class NavbarMenu extends Component {
    constructor(props) {
      super(props);
  
      this.toggle = this.toggle.bind(this);
      this.state = {
        isOpen: false
      };
    }
    toggle() {
      
      if(window.innerWidth<768){
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      
    }
   
    render() {
      return (
        <div>
          <Navbar color="orange" expand="md">
            <NavbarBrand href="/">DoubleMaker</NavbarBrand>
            <NavbarToggler className="navbar-dark white-border"  onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                     <NavLink to="/"  onClick={this.toggle} >Home</NavLink>
                </NavItem>
                <NavItem>
                     <NavLink to="/generator"  onClick={this.toggle} >Generator</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/ideafeed"  onClick={this.toggle} >Ideafeed</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/about"  onClick={this.toggle} >About</NavLink>
                </NavItem>
                
               
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      );
    }
  }

export default withRouter(NavbarMenu);