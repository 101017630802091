import React, {Component} from 'react';
import '../css/Home.css';
import mobilehand1 from '../images/mobile-hand-1.jpeg';
import mobilehand2 from '../images/mobile-hand-2.jpeg';
import {NavLink} from 'react-router-dom';

class Home extends Component {

    render(){
        return(
            <div className="container home">
                <h3 className="center">
                    DoubleMaker<br/><small>High Level Tennis Application</small>
                </h3>
                <div className="col-12 ">
                    <div className="col-12">
                        <div className="header-image" alt="Doublemaker" ></div> 
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="col-12  center"><h4>Create your team's double line-up as a Pro</h4></div>
                
                    <div className="col-12">
                        Got some headache trying to create the best double line-up during team matches?
                        Doubles are the main deciding factor? Do you need some help for the important moments?<br/>
                        If so, then imagine someone will tell you the best combination!
                        What's more, including previous player statistics!<br/>
                        DoubleMaker is the application that provides you incredible features like these and much more!             
                       
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="col-12  center"><h4>Easily input your players</h4></div>             
                    <div className="col-12 col-sm-6 inline-block left">
                        <img alt="Tennis double players" className="mobileinhandimg" src={mobilehand1} />
                    </div>
                    <div className="col-12 col-sm-6 inline-block vertical-middle"> 
                        Just type  your player names and double rankings in a few seconds on your smart device, then generate all the combinations.
                        <div className="col-12 center">
                            <NavLink to="/generator" className="orangebtn">Try it!</NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="col-12 center"><h4>Generate combinations in a second</h4> </div>
                    <div className="col-12 col-sm-6  inline-block vertical-middle">                        
                        The generation method is so cool and fast and helps you to select the best combination to beat the opponent team.
                        With this tool you will coordinate your team's performance on a new high level.                       
                        <div className="col-12 center">
                            <NavLink to="/generator" className="orangebtn">Try it!</NavLink>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 inline-block right">
                        <img alt="Tennis double variations" className="mobileinhandimg" src={mobilehand2} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;