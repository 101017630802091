import React, {Component} from 'react';
import { connect } from 'react-redux';
import { playersSetData } from '../actions/playerActions';
import { setGeneratedOptions } from '../actions/playerActions';

import DoubleTables from './DoubleTables';
import axios from 'axios';
import '../css/Home.css';


import { Button,Table, Input } from 'reactstrap';

const api_url = "https://doublemaker.petkolsystems.com/api/v1/";
class playerTable extends Component {

    
    componentDidMount() {
        this.props.setData(api_url+'gettestplayers/', null);
        this.props.setOptions([]);
    }
    handleChange = (e) => {
        let namevalue = null;
        let rankingvalue = null;
        let inputname = e.target.name;
        let id = null;
        if(inputname.indexOf("playername") !== -1){
          id = inputname.replace("playername","");
          namevalue = e.target.value;
        }else {
          id = inputname.replace("playerranking","");
          rankingvalue = e.target.value;
        }

        let newplayers = [];
        this.props.players.forEach(element => {
            let newplayer = null;            
            if(element.id === parseInt(id,10)){              
                newplayer =  {id:element.id,name:namevalue!==null ? namevalue : element.name,ranking:rankingvalue!==null ? rankingvalue : element.ranking};               
            }else {
                newplayer = {id:element.id,name:element.name,ranking:element.ranking};
            }           
            newplayers.push(newplayer);
        });      
        this.props.setData(null,newplayers);
    }
   

    handleSubmit = (e) => {
        e.preventDefault();     
        //var postplayers= JSON.stringify({postplayers:this.props.players});   
        var postplayers = JSON.stringify(this.props.players); 
        axios.post(api_url+'generatedoubles/',  {postplayers} )
         .then(res => {          
           this.props.setOptions(res.data);
           
        })

    }

    render(){
      
        // if (this.props.hasErrored) {
        //     return <p>Sorry! There was an error loading the testplayers</p>;
        // }
        // if (this.props.isLoading) {
        //     return <p>Loading…</p>;
        // }
        
        const {generatedOptions} = this.props;
    
        const {players} = this.props;    
       
        const playerList = players.length ? (
            players.map(player => {
               
                return ( 
                    <tr key={player.id}>
                        <td><Input name={"playername"+player.id} type="text" className="" value={player.name} onChange={this.handleChange}/></td>
                        <td><Input name={"playerranking"+player.id} type="number" className="" value={player.ranking} onChange={this.handleChange}/></td>                        
                    </tr>
                )
            })
        ): (
            <tr className="center"><td>No players yet</td></tr>
        
        );

        const generateButton = players.length ? (
            
                <div className="center">
                    <Button type="submit"  className="p-3 mb-2 bg-success text-white">Let's generate</Button>
                </div>
            
        ) : (
            <div></div>
        )
            
        

     

        return(
        
            <div className="container">
                <div className="row">   
                    <div className="col-sm-12">
                        <h3 className="center">
                            Generate your team's doubles
                        </h3>
                    </div>
                    <div className="col-sm-12 playersbox">
                        <form onSubmit={this.handleSubmit}>
                            <Table className="playertable">
                                <thead>
                                    <tr>
                                        <th>Player name</th>
                                        <th>Double ranking</th>                            
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    {playerList}
                                </tbody>               
                            </Table>
                      
                            {generateButton}
                        
                            
                        </form>
                    </div>
                    
                    <DoubleTables generatedOptions={generatedOptions}/>
                   
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {    
    return {
        players: state.players,
        hasErrored: state.playersHasErrored,
        isLoading: state.playersIsLoading,
        generatedOptions: state.generatedOptions
        
    }
}



const mapDispatchToProps = (dispatch) => {   
    return {
        setData: (url,players) => { dispatch(playersSetData(url,players))},
        setOptions: (options) => { dispatch(setGeneratedOptions(options))}
       
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(playerTable);