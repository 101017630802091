import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import playerReducer from '../reducers/playerReducer';
export default function configureStore(initialState) {
    return createStore(
        playerReducer,        
        initialState,
        applyMiddleware(thunk)
    );
}