import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {Button } from 'reactstrap';


let AddNewIdeaForm = props => {


    const { handleSubmit } = props;

    const openAddNewIdea = () => {
        let box = document.getElementById("addnewideabox");
        let display = box.style.display;
        if(display === "" || display === "none"){           
            display = "block";
        }else {          
            display = "none";
        }
        box.style.display = display;
    }




    return <form onSubmit={handleSubmit} className="form">

        <div className="col-12 center">
            <Button id="addnewideaboxopener" color="success" onClick={openAddNewIdea} >Add new</Button>
        </div>
        <div className="col-12">
            <div id="addnewideabox" className="col-12 display-none center idea">                      
                <div className="field">
                    <div className="control">
                    <Field className="form-control input"  name="title" component="input" type="text" maxLength="200" placeholder="Type the Idea title (Max. 200 chars)"/>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                    <Field className="form-control input"  name="text" component="textarea" componentClass="textarea" rows="2" maxLength="1000" placeholder="Type the Idea text  (Max. 1000 chars)" />
                    </div>
                </div> 
                <div className="field center">
                    <Button type="submit">Send</Button>   
                </div>
            </div>
        </div>
        
    </form>;
    
  };

  export default reduxForm({
    form: 'newidea' // a unique identifier for this form
  })(AddNewIdeaForm)

