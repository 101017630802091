import React,{Component} from "react";
import {Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
 
class Popup extends Component {
    constructor(props) {
      super(props);
      this.state = {
        show: props.show,
        message: props.message,
        title: props.title
      };

    }


 
    render() {  
  
  
      return (  
          <Modal show={this.props.show}  onHide={this.props.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.message}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onHide}>
                Ok
              </Button>         
            </Modal.Footer>
          </Modal>
        
      );
    }
  }
  
  export default Popup;