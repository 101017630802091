import React  from 'react';
import '../css/Footer.css';
const Footer = (props) => {
    return (
      
         <div className="footer">
             <div>DoubleMaker Copyright  &copy; 2019</div>
         </div>
    )
}

export default Footer;