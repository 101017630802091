import React, { Component } from 'react';
import NavbarMenu from './components/NavbarMenu';
import { Route, Switch} from 'react-router-dom';
import { HashRouter } from 'react-router-dom'
import Home from './components/Home';
import PlayerTable from './components/PlayerTable';
import IdeaFeed from './components/IdeaFeed';
import Contact from './components/Contact';
import About from './components/About';
import Footer from './components/Footer';

class App extends Component {
  
  render() {
    return (
      <HashRouter>
      <div className="App">
        <NavbarMenu />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/generator' component={PlayerTable} />
          <Route path='/about' component={About} />
          <Route path='/ideafeed' component={IdeaFeed} />
          <Route path='/contact' component={Contact} />
        </Switch>
        <Footer />
      </div>
      </HashRouter>
    );
  }
}

export default App;
