import React, { Component } from 'react';


class doubleTables extends Component {
  

    ShowTitle(props) {
        const isShown = props.isShown;
        if (isShown) {
          return <h4 className="center">Generated doubles</h4>;
        }
        return ;
    }

      
    displayCourt(court) {

        const itemsarray = court.split("[###]");
        //console.log(itemsarray);
        const html = 
                <div className="col-sm-12 option-card-body">
                    <div className="col-1 col-sm-1  inline-block-no-side-padding">{itemsarray[0]}</div>
                    <div className="col-4 col-sm-4  inline-block-no-side-padding">{itemsarray[1]}</div>
                    <div className="col-1 col-sm-1  inline-block-no-side-padding">{itemsarray[2]}</div>
                    <div className="col-4 col-sm-4  inline-block-no-side-padding">{itemsarray[3]}</div>
                    <div className="col-1 col-sm-1  inline-block-no-side-padding">{itemsarray[4]}</div>
                    <div className="col-1 col-sm-1  inline-block-no-side-padding">{itemsarray[5]}</div>
                </div>;
            
        

       
        return html;

    }

  render() {
    
    const {generatedOptions} = this.props;
    const isShown = generatedOptions ? true : null ;
        let c=1;       
        const generatedOptionsList = generatedOptions ? (

            generatedOptions.map(generatedOption => {   
                            
                return (
                        <div className="col-sm-12 col-md-6 col-lg-4 inline-block">
                            <div className="col-sm-12 option-card">                          
                                <div className="col-sm-12 option-card-header center">Variant #{c++}</div>
                                {this.displayCourt(generatedOption[0])}
                                {this.displayCourt(generatedOption[1])}
                                {this.displayCourt(generatedOption[2])}  
                            </div>                         
                        </div>   
                            
                )
            })
        ): (
            <div></div>
        
        );   

    return (        
      <div className="col-sm-12 generationbox">
        {this.ShowTitle(isShown)}
        {generatedOptionsList}
      </div>
    );
  }
}

export default doubleTables;