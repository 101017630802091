import React, {Component} from 'react';
import '../css/Home.css';
class ProfileImage extends Component  {

    state = {
        clicked: false
    }
  
    render(){
        const properties = this.props;  
          
        return(
        
               <div  className="col-12 col-sm-6 inline-block center">
                    <div className="col-12"> 
                        <img alt={properties.profilename} className={properties.className}  title={ (this.state.clicked ? "Click for Business image!" : "Click for Cooler image!")} onClick={() => {this.state.clicked ? this.setState({clicked: false}) : this.setState({clicked: true})}} src={ (this.state.clicked ? properties.coolimage : properties.businessimage)} />                           
                    </div>
                    <div className="col-12">
                        {properties.profilename}<br/>
                        {properties.position}<br/>
                        <a href="mailto:&#100;&#111;&#117;&#098;&#108;&#101;&#109;&#097;&#107;&#101;&#114;&#064;&#112;&#101;&#116;&#107;&#111;&#108;&#115;&#121;&#115;&#116;&#101;&#109;&#115;&#046;&#099;&#111;&#109;" target="_top">&#100;&#111;&#117;&#098;&#108;&#101;&#109;&#097;&#107;&#101;&#114;&#064;&#112;&#101;&#116;&#107;&#111;&#108;&#115;&#121;&#115;&#116;&#101;&#109;&#115;&#046;&#099;&#111;&#109;</a>
                    </div>                        
                </div>                   
           
        )
    }
}



export default ProfileImage;