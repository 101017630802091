import React, {Component} from 'react';
import '../css/Home.css';
import { connect } from 'react-redux';
import { ideasSetData } from '../actions/playerActions';
import { popupSetData } from '../actions/playerActions';
import { newIdeaSetData } from '../actions/playerActions';
import { responseNewIdeaData } from '../actions/playerActions';
import axios from 'axios';
import AddNewIdeaForm from'./AddNewIdeaForm';
import PopupReact from'./Popup';
import {Button } from 'reactstrap';
const api_url = "https://doublemaker.petkolsystems.com/api/v1/";
class IdeaFeed extends Component {

    componentDidMount(){
        this.props.ideasSet(api_url+'ideas/read.php',null); 
        this.props.popupSet([false,null,null]); 
    }

    handleSubmit = (e) => {
  
        var postidea = JSON.stringify({postidea:e});    
        axios.post(api_url+'ideas/create.php',  postidea )
        .then((response) => {
      
            if (response.status===201) {                
                this.props.responseNewIdea(response.data);
                
                this.props.popupSet([true,response.data.message,"Sending new Idea"]);
            }                    
            
        }).catch(error => {
            if(error.response){
                this.props.popupSet([true,error.response.data.message,"Sending new Idea"]);
            }else {
                this.props.popupSet([true,"Sorry, something went wrong! Check if all the input boxes are filled!","Sending new Idea"]);
            }
            
        });
    }

    handleChange = (e) => {
        
        this.props.newIdeaSet(e);
    }

    handleLike = (ideaid) => {
        var postideaid= JSON.stringify({id:ideaid});    
        
        axios.post(api_url+'ideas/like.php',  postideaid)
        .then((response) => {
           
            if (response.status===201) {                
                this.props.ideasSet(api_url+'ideas/read.php',null); 
                this.props.popupSet([true,response.data.message,"Like Idea"]);
                
            }                    
            
        }).catch(error => {
            if(error.response){
                this.props.popupSet([true,error.response.data.message,"Like Idea"]);
            }else {
                this.props.popupSet([true,"Sorry, something went wrong! Try again later!","Like Idea"]);
            }
            
        });

    }

    handleClose(popup) {
       
        this.props.popupSet([false,popup[1],popup[2]]);
    }
    



    render(){

        const {ideas} = this.props;
        const popup = (typeof this.props.popup[0] === 'object' || !this.props.popup[0] ) ? false : this.props.popup;
   
        const ideaList = ideas ? (
            ideas.map(idea => {
                return (
                    <div className="col-12 idea" key={idea.id}>
                        <div className="col-12 idea-title">                       
                            <div className="col-12"><h4 className="">{idea.title}</h4></div>
                        </div>
                        <div className="col-12 idea-content">                            
                            <div className="col-12 col-sm-8 inline-block vertical-top"><p className="overflow-x">{idea.text}</p></div>
                            <div className="col-6 col-sm-2 inline-block vertical-top center"><p><Button onClick={() => {this.handleLike(idea.id)}} color="primary">Like ({idea.likes})</Button></p></div>                            
                            <div className="col-6 col-sm-2 inline-block vertical-top center"><p><Button color="info">{idea.status}</Button></p></div>
                        </div>
                    </div>
                )
            })
        ): (
            <div className="center">No ideas yet</div>
        
        )

       
        return(
            <div className="container home">
                <h3 className="center">
                    IdeaFeed
                </h3>
                <div className="col-12 center">
                   Share Your ideas with us!<br/>
                   Feel free to write on your own language
                </div>
                {popup[0]===true ? <PopupReact onHide={()=>{this.handleClose(popup)}} show={popup[0]} message={popup[1]} title={popup[2]}   /> : null}
                <AddNewIdeaForm onSubmit={this.handleSubmit} onChange={this.handleChange} />
                <div className="col-12">                   
                    {ideaList}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ideas: state.ideas,
        ideaform: state.ideaform,
        popup: state.popup
    }
}

const mapDispatchToProps = (dispatch) => {   
    return {
        ideasSet: (url,ideas) => { dispatch(ideasSetData(url,ideas))},     
        popupSet: (popup) => { dispatch(popupSetData(popup))}, 
        newIdeaSet: (values) => { dispatch(newIdeaSetData(values))},       
        responseNewIdea: (response)=> { dispatch(responseNewIdeaData(response))},       
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(IdeaFeed);