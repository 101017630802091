
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
export const playersHasErrored = (state = false, action) => {
    switch (action.type) {
        case 'PLAYERS_HAS_ERRORED':
            return action.hasErrored;
        default:
            return state;
    }
}
export const playersIsLoading = (state = false, action) => {
    switch (action.type) {
        case 'PLAYERS_IS_LOADING':
            return action.isLoading;
        default:
            return state;
    }
}
export const players = (state = [], action) => {
    switch (action.type) {
        case 'PLAYERS_SET_DATA_SUCCESS':        
            return action.players;
        default:
            return state;
    }
}


export const generatedOptions = (state = [], action) => {
    switch (action.type) {
        case 'SET_GENERATED_OPTIONS':       
            return action.generatedOptions;
        default:
            return state;
    }
}

export const ideas = (state = [], action) => {
    
    switch (action.type) {
        case 'IDEAS_SET_DATA_SUCCESS':        
            return action.ideas;
        default:
            return state;
    }
}

export const popup = (state = [], action) => {
    
    switch (action.type) {
        case 'POPUP_SET_DATA_SUCCESS':        
            return action.popup;
        default:
            return state;
    }
}

export const newidea = (state = [], action) => {
    
    switch (action.type) {
        case 'NEWIDEA_SET_DATA_SUCCESS':        
            return action.newidea;
        default:
            return state;
    }
}



//export default playerReducer;
export default combineReducers({
    players,
    playersHasErrored,
    playersIsLoading,
    generatedOptions,
    ideas,
    popup,
    form: formReducer
});