// const initState = 
//  [
//     {id: 1, name: "TESTPLAYER001", ranking: 1},
//     {id: 2, name: "TESTPLAYER002", ranking: 2},
//     {id: 3, name: "TESTPLAYER003", ranking: 3},
//     {id: 4, name: "TESTPLAYER004", ranking: 4},
//     {id: 5, name: "TESTPLAYER005", ranking: 5},
//     {id: 6, name: "TESTPLAYER006", ranking: 6}];
  


export const playersHasErrored = (bool) => {
    return {
        type: 'PLAYERS_HAS_ERRORED',
        hasErrored: bool
    };
}
export const playersIsLoading = (bool) => {
    return {
        type: 'PLAYERS_IS_LOADING',
        isLoading: bool
    };
}
export const playersSetDataSuccess = (players) => {
    return {
        type: 'PLAYERS_SET_DATA_SUCCESS',
        players
    };
}

export function playersSetData(url,players) {
    return (dispatch) => {
        
       
        if(!players){
            dispatch(playersIsLoading(true));
            fetch(url)
             .then((response) => {
                  if (!response.ok) {
                      throw Error(response.statusText);
                }
                 dispatch(playersIsLoading(false));
                
               return response;
             })
             .then((response) => response.json())
             .then((players) => dispatch(playersSetDataSuccess(players)))
              .catch(() => dispatch(playersHasErrored(true)));   
        }else {
            dispatch(playersSetDataSuccess(players)); 
          
        }
         
    };
}



export const setGeneratedOptions = (generatedOptions) => {
    return {
        type: 'SET_GENERATED_OPTIONS',
        generatedOptions
    };
}



export const ideasSetDataSuccess = (ideas) => {
    return {
        type: 'IDEAS_SET_DATA_SUCCESS',
        ideas
    };
}

export function ideasSetData(url,ideas) {
    return (dispatch) => {
        if(!ideas){           
            fetch(url)
             .then((response) => {
                  if (!response.ok) {
                      throw Error(response.statusText);
                }                           
               return response;
             })
             .then((response) => response.json())
             .then((ideas) => dispatch(ideasSetDataSuccess(ideas)))  
        }else {
            dispatch(ideasSetDataSuccess(ideas)); 
          
        }
         
    };
}

    export const popupSetData = (popup) => {
        return {
            type: 'POPUP_SET_DATA_SUCCESS',
            popup
        };
    }



export const newIdeaSetData  = (values) => {    
    return {
        type: 'NEWIDEA_SET_DATA_SUCCESS',
        values
    };
}

export function responseNewIdeaData(response) {
    return (dispatch) => {
        console.log(response);
         
    };
}